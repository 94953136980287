import { ConfigProvider } from "antd"
import { useDarkMode } from "src/hooks/useDarkMode"

const FONTS_SYSTEM = `'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';`

const TOKEN_LIGTH = {
    colorPrimary: "#6E0000",
    fontFamily: FONTS_SYSTEM
}
const TOKEN_DARK = {
    fontFamily: FONTS_SYSTEM,
    colorBgBase: "#151B23",
    colorBgContainer: "#212B36",
    colorText: "#FFF",
    colorTextPlaceholder: "#acacac",
    colorBorder: "#212B36",
    colorPrimaryBgHover: "#151B23",
    colorPrimary: "#6E0000",
    colorPrimaryBg: "#212B36",
    colorIcon: "#FFF",
    colorErrorOutline: "#212B36",
    colorBgElevated: "#151B23",
    colorTextQuaternary: "#acacac",
    colorTextTertiary: "#acacac"
}

export const ProviderAntd = ({
    children
}) => {

    const { darkMode } = useDarkMode()
    
    return (
        <ConfigProvider
            theme={{
                token: darkMode ? TOKEN_DARK : TOKEN_LIGTH,
                components: {
                    Message: {
                        zIndexPopup: 1041
                    },
                    Drawer: {
                        zIndexPopup: 1040
                    }
                }
            }}
        >
            {children}
        </ConfigProvider>
    )
}