export const ExpiredIcon = (props) => {
    return (
        <svg
            width={125}
            height={125}
            viewBox="0 0 125 125"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M44.27 80.73c20.136 0 36.46-16.324 36.46-36.46 0-20.135-16.324-36.458-36.46-36.458-20.135 0-36.458 16.323-36.458 36.459 0 20.135 16.323 36.458 36.459 36.458z"
                fill="#6E0000"
            />
            <path
                d="M44.27 72.917c15.822 0 28.647-12.825 28.647-28.646 0-15.82-12.825-28.646-28.646-28.646-15.82 0-28.646 12.825-28.646 28.646 0 15.82 12.825 28.646 28.646 28.646z"
                fill="#EEE"
            />
            <path d="M41.666 20.833h5.209v23.438h-5.209V20.833z" fill="#000" />
            <path
                d="M58.997 54.568l-4.419 4.419-12.52-12.518 4.418-4.42 12.521 12.519z"
                fill="#000"
            />
            <path
                d="M44.27 49.48a5.208 5.208 0 100-10.418 5.208 5.208 0 000 10.417z"
                fill="#000"
            />
            <path
                d="M44.27 46.875a2.604 2.604 0 100-5.208 2.604 2.604 0 000 5.208z"
                fill="#6E0000"
            />
            <path
                d="M30.99 109.375l37.5-62.76c2.083-3.386 7.03-3.386 8.854 0l37.5 62.76c2.083 3.385-.521 7.813-4.428 7.813h-75c-3.906 0-6.51-4.428-4.427-7.813z"
                fill="#FFC107"
            />
            <path
                d="M68.75 103.906c0-.521 0-1.041.26-1.562.26-.521.521-.782.782-1.302.26-.521.78-.521 1.302-.782.52-.26 1.041-.26 1.562-.26.521 0 1.302 0 1.823.26.521.261 1.042.521 1.302.782.26.26.521.781.781 1.302s.26 1.041.26 1.562c0 .521 0 1.042-.26 1.563-.26.521-.52.781-.78 1.302-.261.521-.782.521-1.303.781-.52.26-1.041.261-1.823.261-.781 0-1.302-.001-1.562-.261s-1.042-.521-1.302-.781c-.26-.261-.521-.781-.782-1.302-.26-.521-.26-1.042-.26-1.563zm7.292-8.073h-5.99l-1.042-25.52h7.813l-.781 25.52z"
                fill="#263238"
            />
        </svg>
    )
}