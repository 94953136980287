import * as React from 'react'

function IconInbox(props) {
  return (
    <svg
      width={60}
      height={58}
      viewBox="0 0 60 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={0.5} width={59} height={58} rx={5} fill={props.color || "#6E0000"} fillOpacity={0.25} />
      <path
        d="M18.75 30.5c-9 0-9 4.028-9 9v2.25C9.75 47.96 9.75 53 21 53h18c9 0 11.25-5.04 11.25-11.25V39.5c0-4.972 0-9-9-9-2.25 0-2.88.473-4.05 1.35l-2.295 2.43c-2.655 2.835-7.155 2.835-9.832 0L22.8 31.85c-1.17-.878-1.8-1.35-4.05-1.35zM45.75 30.5V17c0-4.973 0-9-9-9h-13.5c-9 0-9 4.027-9 9v13.5"
        stroke={props.color || "#6E0000"}
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity={0.4}
        d="M26.738 24.268h7.492M24.87 17.518h11.25"
        stroke={props.color || "#6E0000"}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconInbox
